
import { Component, Emit, Vue, Watch } from 'vue-property-decorator';
import { SyncJobStatus } from '@/enums/SyncJobStatus';

@Component({
  components: {},
})
export default class SyncFilter extends Vue {
  public selectedStatus: SyncJobStatus | null = null;

  get selection() {
    return {
      status: this.selectedStatus,
    };
  }

  get status(): string[] {
    return Object.values(SyncJobStatus);
  }

  @Watch('selection')
  @Emit('change')
  public onSearch() {
    return this.selection;
  }
}
