export default [
  {
    text: 'sync.table.user',
    value: 'user',
    default: true,
    type: 'slot',
    align: 'left',
  },
  {
    text: 'sync.table.status',
    value: 'status',
    default: true,
    align: 'left',
  },
  {
    text: 'sync.table.createdAt',
    value: 'createdAt',
    default: true,
    type: 'slot',
    align: 'left',
  },
  {
    text: 'sync.table.finishedAt',
    value: 'finishedAt',
    default: true,
    type: 'slot',
    align: 'left',
  },
  {
    text: '',
    value: 'config',
    default: true,
    type: 'slot',
    align: 'right',
    sortable: false,
  },
  { text: '', value: 'data-table-expand', default: true },
];
