
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { ColumnHeader } from '@/interfaces/components/ColumnHeader';
import headers from './headers';
import { Permission } from '@/enums/Permission';
import { Page } from '@/interfaces/api/Page';
import SyncFilter from './SyncFilter.vue';
import { VExpansionPanels } from 'vuetify/lib';
import { SyncJob } from '@/interfaces/models/SyncJob';
import { Venue } from '@/interfaces/models/Venue';
import EntriesTable from './EntriesTable.vue';
import SyncCreatedTable from './SyncCreatedTable.vue';
import SyncDeletedTable from './SyncDeletedTable.vue';

interface Panels {
  [key: number]: number[];
}

@Component({
  components: { SyncFilter, VExpansionPanels, EntriesTable, SyncCreatedTable, SyncDeletedTable },
})
export default class SyncTable extends Vue {
  @Prop({ type: Array, required: true }) public venues!: Venue[];
  @Prop({ type: Array, required: true }) public items!: SyncJob[];
  @Prop({ type: Number, required: true }) public total!: number;
  @Prop({ type: Boolean, default: true }) public pagination!: boolean;

  public expandValue: Panels = { 0: [], 1: [], 2: [] };
  public page: number = 1;
  public rowsPerPage: number = 10;
  public sourceHeaders = this.accessibleHeaders;
  public headers: ColumnHeader[] = this.accessibleHeaders.filter((c: ColumnHeader) => c.default);

  get accessibleHeaders() {
    return headers.filter((c: ColumnHeader) => {
      if (
        c.value === 'isControlArticle' &&
        this.$can(Permission.ARTICLE_CONTROL_ACTIVATE) &&
        this.$can(Permission.ARTICLE_CONTROL_DEACTIVATE)
      ) {
        return true;
      }
      return c.value !== 'isControlArticle';
    });
  }

  get tableHeaders() {
    return this.headers.map((h: ColumnHeader) => ({ ...h, text: this.$t(h.text) }));
  }

  get pageCount() {
    if (this.pagination) {
      return Math.ceil(this.total / this.rowsPerPage);
    }

    return 0;
  }

  get entriesKeyProps() {
    return [
      'article',
      'articleCategory',
      'constrain',
      'optionArticle',
      'optionGroup',
      'availableHours',
      'table',
      'tag',
      'venue',
    ];
  }

  public expandPanel(index: number) {
    if (!this.expandValue[index]) {
      this.expandValue[index] = [0, 1, 2];
      return;
    }
    this.expandValue[index] = this.expandValue[index]?.length > 0 ? [] : [0, 1, 2];
  }

  public arrStringToObject(arr: string[]) {
    return arr.map((value: string) => ({ id: value }));
  }

  public getVenueNameById(id: string) {
    const venue = this.venues.find((item: Venue) => item._id === id);
    if (!venue) return 'Venue';
    return venue.name;
  }

  @Emit('page')
  @Watch('page')
  public onPageChange(): Page {
    if (this.$route.query.page && parseInt(this.$route.query.page as string, 10) !== this.page) {
      // @ts-ignore
      this.$router.push({ query: { page: this.page, limit: this.rowsPerPage } }).catch((e) => {
        throw Error(`${e}`);
      });
    }

    return { page: this.page, limit: this.rowsPerPage };
  }
}
