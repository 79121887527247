
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import Notification from '@/mixins/Notification';
import { Page } from '@/interfaces/api/Page';
import { namespace } from 'vuex-class';
import VWrapper from '@/components/shared/VWrapper.vue';
import { SyncConfig } from '@/interfaces/models/SyncConfig';
import { Venue } from '@/interfaces/models/Venue';
import SyncJobTable from '@/components/aggregatorSyncs/SyncJobTable.vue';
import Filter from '@/interfaces/api/Filter';
import { AggregatorSyncJob } from '@/interfaces/models/AggregatorSyncJob';
import { Permission } from '@/enums/Permission';

enum SyncKey {
  WOLT = 'wolt',
  UBER_EATS = 'uberEats',
  JET = 'jet',
}
const venue = namespace('venue');
const aggregatorSyncs = namespace('aggregatorSyncs');

@Component({
  components: { VWrapper, SyncJobTable },
})
export default class AggregatorSyncs extends mixins(Notification) {
  @venue.State('active') public venue!: Venue;
  @aggregatorSyncs.Action('fetchWolt') fetchWolt!: any;
  @aggregatorSyncs.Action('fetchJet') fetchJet!: any;
  @aggregatorSyncs.Action('fetchUberEats') fetchUberEats!: any;
  @aggregatorSyncs.Action('setFilter') public setFilter!: any;
  @aggregatorSyncs.State((state) => state.wolt.items) public wolt!: AggregatorSyncJob[];
  @aggregatorSyncs.State((state) => state.jet.items) public jet!: AggregatorSyncJob[];
  @aggregatorSyncs.State((state) => state.uberEats.items) public uberEats!: AggregatorSyncJob[];
  @aggregatorSyncs.State((state) => state.wolt.pagination.total) public totalWolt!: number;
  @aggregatorSyncs.State((state) => state.jet.pagination.total) public totalJet!: number;
  @aggregatorSyncs.State((state) => state.uberEats.pagination.total) public totalUberEats!: number;
  @aggregatorSyncs.State((state) => state.wolt.filter) public filterWolt!: Filter;
  @aggregatorSyncs.State((state) => state.jet.filter) public filterJet!: Filter;
  @aggregatorSyncs.State((state) => state.uberEats.filter) public filterUberEats!: Filter;

  public configDialog: Boolean = false;
  public config: SyncConfig | null = null;

  public async mounted() {
    if (this.$can(Permission.WOLT_SYNC_JOB_VIEW)) {
      this.setFilter({ filter: { page: 1, limit: 10 }, key: SyncKey.WOLT });
      await this.fetchWolt();
    }

    if (this.$can(Permission.JET_SYNC_JOB_VIEW)) {
      this.setFilter({ filter: { page: 1, limit: 10 }, key: SyncKey.JET });
      await this.fetchJet();
    }

    if (this.$can(Permission.UBER_EATS_SYNC_JOB_VIEW)) {
      this.setFilter({ filter: { page: 1, limit: 1 }, key: SyncKey.UBER_EATS });
      await this.fetchUberEats();
    }
  }

  public async onPage(page: Page, provider: string) {
    if (page) {
      this.setFilter({ filter: { ...this.filterWolt, ...page }, key: SyncKey.WOLT });
      if (provider === SyncKey.WOLT) {
        await this.fetchWolt();
      }
      if (provider === SyncKey.JET) {
        await this.fetchJet();
      }
      if (provider === SyncKey.UBER_EATS) {
        await this.fetchUberEats();
      }
    }
  }

  public async onSearch(queries: any, provider: string) {
    this.setFilter({ filter: { page: 1, limit: 10, ...queries }, key: provider });
    if (provider === SyncKey.WOLT) {
      await this.fetchWolt();
    }
    if (provider === SyncKey.JET) {
      await this.fetchJet();
    }
    if (provider === SyncKey.UBER_EATS) {
      await this.fetchUberEats();
    }
  }
}
