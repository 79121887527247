
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { ColumnHeader } from '@/interfaces/components/ColumnHeader';
import headers from './headers';
import { Page } from '@/interfaces/api/Page';
import { VExpansionPanels } from 'vuetify/lib';
import { OrderProviderSyncJobStatus } from '@/enums/OrderProviderSyncJobStatus';
import { AggregatorSyncJob } from '@/interfaces/models/AggregatorSyncJob';

@Component({
  components: { VExpansionPanels },
})
export default class SyncJobTable extends Vue {
  @Prop({ type: Array, required: true }) public items!: AggregatorSyncJob[];
  @Prop({ type: Number, required: true }) public total!: number;
  @Prop({ type: Boolean, default: true }) public pagination!: boolean;

  public page: number = 1;
  public rowsPerPage: number = 10;
  public status: string = '';

  get tableHeaders() {
    return headers.map((h: ColumnHeader) => ({ ...h, text: this.$t(h.text) }));
  }

  get pageCount() {
    if (this.pagination) {
      return Math.ceil(this.total / this.rowsPerPage);
    }

    return 0;
  }

  get selection() {
    return {
      status: this.status,
    };
  }

  @Watch('selection')
  @Emit('search')
  public onSearch() {
    return this.selection;
  }

  get statusSyncJob() {
    return Object.values(OrderProviderSyncJobStatus).map((status: OrderProviderSyncJobStatus) => ({
      value: status,
      text: this.$t(`aggregatorSyncs.status.${status}`),
    }));
  }

  @Emit('page')
  @Watch('page')
  public onPageChange(): Page {
    return { page: this.page, limit: this.rowsPerPage };
  }
}
