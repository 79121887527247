export default [
  {
    text: 'sync.table.id',
    value: 'id',
    default: true,
    align: 'left',
  },
  {
    text: 'sync.table.changes',
    value: 'changes',
    default: true,
    align: 'left',
  },
];
