
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ColumnHeader } from '@/interfaces/components/ColumnHeader';
import headers from './headersDeletedJobTable';

@Component
export default class SyncDeletedTable extends Vue {
  @Prop({ type: Array, required: true }) public items!: string[];
  @Prop({ type: Boolean, default: true }) public pagination!: boolean;
  @Prop({ type: String, default: true }) public title!: string;

  get tableHeaders() {
    return headers.map((h: ColumnHeader) => ({ ...h, text: this.$t(h.text) }));
  }
}
