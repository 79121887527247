
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ColumnHeader } from '@/interfaces/components/ColumnHeader';
import headers from './headersUpdatedJobTable';
import { ChangedItemAsKey, ChangedItem } from '@/interfaces/models/SyncJobEntry';

@Component
export default class EntriesTable extends Vue {
  @Prop({ type: Array, required: true }) public items!: ChangedItem[] | ChangedItemAsKey[];
  @Prop({ type: Boolean, default: true }) public pagination!: boolean;
  @Prop({ type: String, default: true }) public title!: string;

  get initialValues() {
    return this.serializeArray(this.items);
  }

  public serializeArray(arr: ChangedItem[] | ChangedItemAsKey[]) {
    const result: ChangedItem[] = [];

    arr.forEach((item: ChangedItem | ChangedItemAsKey) => {
      if (item.id && item.changes) {
        result.push({
          id: item.id as string,
          changes: item.changes,
        });
      } else {
        Object.values(item as ChangedItemAsKey).forEach((subItem: ChangedItem) => {
          result.push({
            id: subItem.id,
            changes: subItem.changes,
          });
        });
      }
    });

    return result;
  }

  public formatToString(obj: Record<string, any>): string {
    return this.changesToStrArr(obj).join(', ');
  }

  changesToStrArr(obj: Record<string, any>, parentCursor?: string) {
    const changeStrArr: string[] = [];
    const keys: string[] = Object.keys(obj);
    for (const key of keys) {
      const fullKeyName: string = parentCursor ? `${parentCursor}.${key}` : key;
      if (Array.isArray(obj[key]) && typeof obj[key] === 'object') {
        changeStrArr.push(...this.changesToStrArr(obj[key], fullKeyName));
      } else if (Array.isArray(obj[key])) {
        changeStrArr.push(obj[key].join(', '));
      } else if (typeof obj[key] === 'object') {
        changeStrArr.push(...this.changesToStrArr(obj[key], fullKeyName));
      } else {
        changeStrArr.push(`${fullKeyName}: ${obj[key]}`);
      }
    }
    return changeStrArr;
  }

  get tableHeaders() {
    return headers.map((h: ColumnHeader) => ({ ...h, text: this.$t(h.text) }));
  }
}
